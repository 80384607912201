import styled from 'styled-components'
import { threadType } from '../../../../../types/thread'
import { useContext } from 'react'
import { HistoryContext } from '../../../../../contexts/history'
import { COLORS } from '../../../../../utils/colors'
import archiveIcon from '../../../../../assets/archive.svg'
import deleteIcon from '../../../../../assets/delete.svg'
import { Icon } from './Icon'

interface ListItemProps {
    item: threadType
}

const ListItemStyled = styled.li<{ isSelected: boolean }>`
    font-weight: 300;
    background-color: ${({ isSelected }) =>
        isSelected ? COLORS.cinzaDesbotado : 'transparent'};
    cursor: pointer;
    width: 250px;
    align-self: center;
    margin-left: 10px;
    border-radius: 5px;
    font-size: 15px;
    text-align: center;
    position: relative;
    display: flex;
    overflow: visible;
    & span {
        text-align: start;
        padding: 10px 15px;
        display: inline-block;
        height: 100%;
        flex: 1;
    }
`
const Icons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-right: 10px;
`
export default function ListItem({ item }: ListItemProps) {
    const { selected, setSelected, archiveThread, deleteThread } =
        useContext(HistoryContext)
    const isSelected = selected?.guid === item.guid

    function toggleItem() {
        if (isSelected) {
            return
        }
        setSelected(item)
    }

    return (
        <ListItemStyled isSelected={isSelected}>
            <span onClick={toggleItem}>
                {item.name === 'Undefined'
                    ? 'Novo Chat'
                    : item.name.slice(0, 23)}
                {item.name?.length < 23 ? '' : '...'}
            </span>
            <Icons>
                <Icon
                    tooltip="Excluir"
                    onClick={() => archiveThread(item.guid)}
                    position="right"
                    src={deleteIcon}
                    alt="Deletar Thread"
                />
            </Icons>
        </ListItemStyled>
    )
}
