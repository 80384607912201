import reportIcon from '../../../../../assets/feedback.svg'
import regenerateIcon from '../../../../../assets/regenerate.svg'
import copyIcon from '../../../../../assets/copy.svg'
import styled from 'styled-components'
import { messageType } from '../../../../../types/message'
import { copyToClipboard } from '../../../../../utils/copyToClippboard'
import { useContext, useRef, useState } from 'react'
import { COLORS } from '../../../../../utils/colors'
import { AssistantApi } from '../../../../../services/AssistantApi.service'
import { HistoryContext } from '../../../../../contexts/history'
import { useLoading } from '../../../../../globalComponents/useLoading'
import Tooltip from '../../../../../globalComponents/tooltip'
import { StyledTooltip } from '../../../../../globalComponents/tooltip'

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background-color: transparent;
`

const FeedbackButton = styled.button`
    padding: 5px 5px;
    background-color: ${COLORS.branco};
    border: 1px solid ${COLORS.cinzaEscuro};
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 30px;
    z-index: 99999999;
    cursor: pointer;
`
const FeedbackWrapper = styled.div`
    position: absolute;
    bottom: 50px;
    background-color: ${COLORS.branco};
    left: 0;
    padding: 20px;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const ToolsButton = styled.button`
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 15px;
    margin-left: 0;

    &:hover ${StyledTooltip} {
        visibility: visible;
    }
`

const FeedbackInput = styled.input`
    padding: 5px;
    outline: 0;
    border-radius: 5px;
    z-index: 99999999;
    position: relative;
`
const StyledTools = styled.div`
    display: flex;
    position: relative;
    & img {
        width: 15px;
    }
`

const ToastMessage = styled.span`
    font-size: 12px;
    color: ${COLORS.cinzaEscuro};
    position: absolute;
    top: 40px;
    left: 0px;
    cursor: auto;
`

interface ToolsProps {
    message: messageType
    lastAssistantMessage: boolean
}

export default function Tools({ lastAssistantMessage, message }: ToolsProps) {
    const { includeMessageAtArray, selected } = useContext(HistoryContext)
    const { Spinner, setIsLoading, isLoading } = useLoading()
    const toastRef = useRef<HTMLSpanElement>(null)

    const [feedbackMessage, setFeedbackMessage] = useState('')
    const [showInput, setShowInput] = useState(false)

    async function handleCopy() {
        if (!toastRef.current) return
        const answer = await copyToClipboard(message.content)
        toastMessage(answer)
    }

    async function handleRegenerate() {
        try {
            setIsLoading(true)
            const api = new AssistantApi()
            const response = await api.regenerateAnswer(message.thread_id)
            includeMessageAtArray(response)
        } catch (error: any) {
            includeMessageAtArray(error)
        } finally {
            setIsLoading(false)
        }
    }

    async function handleReport() {
        if (!selected) return
        try {
            setIsLoading(true)
            const api = new AssistantApi()
            const response = await api.reportAnswer(
                feedbackMessage,
                message.guid,
                selected.owner_id
            )
            toastMessage('Obrigado pelo feedback.')
            setFeedbackMessage('')
        } catch (error: any) {
            toastMessage('Erro ao registrar feedback. Tente novamente.')
        } finally {
            setIsLoading(false)
            setShowInput(false)
        }
    }

    function toastMessage(message: string) {
        if (!toastRef.current) return
        toastRef.current.innerHTML = message
        setTimeout(() => {
            if (!toastRef.current) return
            toastRef.current.innerHTML = ''
        }, 2000)
    }

    return (
        <StyledTools>
            <Spinner />
            <ToolsButton disabled={isLoading} onClick={handleCopy}>
                <Tooltip left="0" top="-25px" text="Copiar" />

                <img
                    src={copyIcon}
                    alt="Ícone copiar para área de transferência"
                />
            </ToolsButton>
            {lastAssistantMessage && (
                <ToolsButton disabled={isLoading} onClick={handleRegenerate}>
                    <Tooltip left="0" top="-25px" text="Nova resposta" />
                    <img
                        src={regenerateIcon}
                        alt="Ícone para gerar novamente"
                    />
                </ToolsButton>
            )}

            {
                <ToolsButton
                    disabled={isLoading}
                    onClick={() => {
                        setShowInput((prev) => !prev)
                    }}
                >
                    <Tooltip left="0" top="-25px" text="Reportar erro" />

                    <img src={reportIcon} alt="Ícone para reportar o texto" />
                </ToolsButton>
            }

            {showInput && (
                <FeedbackWrapper>
                    <Background onClick={() => setShowInput(false)} />
                    <FeedbackInput
                        autoFocus
                        value={feedbackMessage}
                        placeholder="O que deseja reportar?"
                        onChange={(e) => setFeedbackMessage(e.target.value)}
                    />
                    <FeedbackButton type="button" onClick={handleReport}>
                        Enviar Feedback
                    </FeedbackButton>
                </FeedbackWrapper>
            )}
            <ToastMessage ref={toastRef}></ToastMessage>
        </StyledTools>
    )
}
