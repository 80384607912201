import styled from 'styled-components'

interface ChatCounterProps {
    text: string
    maxLength: number
}

const StyledCounter = styled.span<{
    color: string
}>`
    position: absolute;
    top: 0;
    right: 80px;
    font-size: 12px;
    font-weight: 700;
    color: ${({ color }) => color};
`
export default function ChatCounter({ text, maxLength }: ChatCounterProps) {
    const length = text.length
    const limitToYellow = length >= maxLength * 0.8 && length < maxLength
    const limitToRed = length >= maxLength

    const color = limitToRed ? '#cc3300' : limitToYellow ? '#ffcc00' : '#339900'

    return (
        <StyledCounter color={color}>
            {length}/{maxLength}
        </StyledCounter>
    )
}
