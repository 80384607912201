import styled from 'styled-components'
import Tooltip, { StyledTooltip } from '../../../../../globalComponents/tooltip'

const StyledIcon = styled.img<{ position: 'left' | 'right' }>`
    width: 20px;
    top: 10px;
    right: ${({ position }) => (position === 'left' ? '40px' : `15px`)};
    opacity: 0.2;
    transition: all 0.3s;
    overflow: hidden;
    &:hover {
        opacity: 1;
    }
`
const Wrapper = styled.div`
    position: relative;

    &:hover ${StyledTooltip} {
        visibility: visible;
    }
`
interface IconProps {
    position: 'left' | 'right'
    onClick: () => any
    src: string
    alt: string
    tooltip: string
}
export function Icon({ onClick, position, src, alt, tooltip }: IconProps) {
    return (
        <Wrapper>
            <Tooltip top="0" left="-65px" text={tooltip} />
            <StyledIcon
                onClick={onClick}
                position={position}
                src={src}
                alt={alt}
            />
        </Wrapper>
    )
}
