import { useContext, useState } from 'react'
import arrowUpIcon from '../../../../../assets/arrowUp.svg'
import { AssistantType } from '../../../../../types/assistant'
import { HistoryContext } from '../../../../../contexts/history'
import styled from 'styled-components'
import { COLORS } from '../../../../../utils/colors'
import { CookiesService } from '../../../../../services/Cookie.service'
import Tooltip, { StyledTooltip } from '../../../../../globalComponents/tooltip'

const List = styled.ul`
    list-style: none;
    border-radius: 8px;
    padding: 3px;
    position: absolute;
    width: 250px;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    background-color: ${COLORS.cinzaDesbotado};
`

const Item = styled.li`
    border-bottom: none;
    padding: 10px 25px;
    color: ${COLORS.cinzaEscuro};
    font-size: 13px;
    text-align: center;

    &:hover {
        background-color: ${COLORS.branco};
        cursor: pointer;
    }

    &:first-child,
    :first-child:hover {
        background-color: transparent;
        cursor: auto;

        border-bottom: 2px solid ${COLORS.cinzaClaro};
    }
`

const Wrapper = styled.div`
    display: flex;
    position: relative;

    &:hover ${StyledTooltip} {
        visibility: visible;
    }
`

export default function ArrowUpButton() {
    const { setAssistant, assistants, includeMessageAtArray } =
        useContext(HistoryContext)
    const [show, setShow] = useState(false)
    async function showAssistants() {
        if (show) {
            setShow(false)
            return
        }
        setShow(true)
    }

    function handleSelectAssistant(item: AssistantType) {
        const cookieService = new CookiesService()
        cookieService.saveAssistant(item)
        setAssistant(item)
        includeMessageAtArray({
            assistant_id: '777',
            author_id: '',
            content: `Você selecionou o Schief: ${item.name}.`,
            created_at: new Date(),
            guid: '777',
            thread_id: '777',
        })
        setShow(false)
    }
    return (
        <Wrapper>
            {show && (
                <List>
                    <Item> Selecione o Schief </Item>
                    {assistants?.map((item) => (
                        <Item
                            key={item.guid}
                            onClick={() => handleSelectAssistant(item)}
                        >
                            {item.name}
                        </Item>
                    ))}
                </List>
            )}
            <Tooltip left="0px" top="-15px" text="Alterar o motor" />

            <img onClick={showAssistants} width="30px" src={arrowUpIcon} />
        </Wrapper>
    )
}
