import styled from 'styled-components'
import { COLORS } from '../utils/colors'

interface TooltipProps {
    left: string
    top: string
    text: string
}

export const StyledTooltip = styled.span<{ top: string; left: string }>`
    visibility: hidden;
    position: absolute;
    z-index: 1000;
    width: max-content;
    font-size: 12px;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
    padding: 5px 10px !important;
    border-radius: 5px;
    background-color: ${COLORS.cinzaClaro};
    color: ${COLORS.cinzaEscuro};
`
export default function Tooltip({ left, top, text }: TooltipProps) {
    return (
        <StyledTooltip top={top} left={left}>
            {text}
        </StyledTooltip>
    )
}
